import styled from "@emotion/styled";
import { BiometryType } from "@capgo/capacitor-native-biometric";
import type { FC, ReactNode } from "react";
import React, { useEffect } from "react";

import { BiometricVerificationBloc } from "src/hybrid/components/BiometricVerification";
import { useBloc } from "src/state/state";
import type { TranslationKey } from "src/types/translationKey";
import FaceIdIcon from "src/ui/components/GlobalOverlays/assets/FaceIdIcon";
import LockIcon from "src/ui/components/GlobalOverlays/assets/LockIcon";
import TouchIdIcon from "src/ui/components/GlobalOverlays/assets/TouchIdIcon";
import Translate from "src/ui/components/Translate/Translate";
import BlockingLoadingOverlayController from "../../BlockingLoadingOverlay/BlockingLoadingOverlayController";

const StyledOverlay = styled.div`
  label: RequestPushPermissions;
  position: relative;
  z-index: 999002;

  nine-bg {
    height: 100vh;
  }
`;

const IconPos = styled.div`
  label: IconPos;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;
`;

export enum PromptWhen {
  UserSentMessage = "user-sent-message",
  AfterShortDelay = "after-short-delay"
}

export interface TextForAuthTypes {
  name: TranslationKey;
  icon: ReactNode;
  title: TranslationKey;
  description: TranslationKey;
  button: TranslationKey;
}

export const textForAuthTypes: Record<
  BiometryType,
  TextForAuthTypes | undefined
> = {
  [BiometryType.NONE]: undefined,
  [BiometryType.FACE_ID]: {
    name: "faceId",
    icon: <FaceIdIcon />,
    title: "signIn.with.faceId.title",
    description: "signIn.with.faceId.description",
    button: "faceId.action"
  },
  [BiometryType.TOUCH_ID]: {
    name: "touchId",
    icon: <TouchIdIcon />,
    title: "signIn.with.touchId.title",
    description: "touchId.description",
    button: "touchId.action"
  },
  [BiometryType.FINGERPRINT]: undefined,
  [BiometryType.FACE_AUTHENTICATION]: undefined,
  [BiometryType.IRIS_AUTHENTICATION]: undefined,
  [BiometryType.MULTIPLE]: undefined
};

const BiometricSetup: FC = () => {
  const [
    { showSetupPrompt, biometricType, isBiometricVerificationEnabled },
    { setupBiometricVerification, closeSetupPrompt }
  ] = useBloc(BiometricVerificationBloc);

  const text: TextForAuthTypes = textForAuthTypes[biometricType] ?? {
    name: "biometric",
    icon: <LockIcon />,
    title: "signInQuicker",
    description: "biometric.description",
    button: "enable_signIn"
  };

  useEffect(() => {
    if (isBiometricVerificationEnabled) {
      void closeSetupPrompt();
    }
  }, [isBiometricVerificationEnabled]);

  const handleDismiss = (): void => {
    void closeSetupPrompt();
    BlockingLoadingOverlayController.startLoading({
      bg: "branded",
      timeout: 5000,
      fadeIn: false
    });
  };

  return (
    <StyledOverlay>
      <nine-overlay
        variant="fullpage-content"
        overlayOpen={showSetupPrompt ? "true" : "false"}
        hide-close
      >
        <nine-bg variant="animate-primary" slot="content">
          <nine-center
            style={{ padding: "var(--space-lg)", textAlign: "center" }}
          >
            <IconPos>{text.icon}</IconPos>
            <h4>
              <Translate msg={text.title} />
            </h4>
            <p>
              {" "}
              <Translate msg={text.description} />
            </p>
            <nine-spacer s="md"></nine-spacer>
            <nine-button
              color="black"
              onClick={(): void => void setupBiometricVerification()}
            >
              <Translate msg={text.button} />
            </nine-button>

            <nine-spacer s="sm"></nine-spacer>
            <nine-button variant="ghost" arrow="" onClick={handleDismiss}>
              <Translate msg="notNow" />
            </nine-button>
          </nine-center>
        </nine-bg>
      </nine-overlay>
    </StyledOverlay>
  );
};

export default BiometricSetup;
